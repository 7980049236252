import type { MaybeRef } from '~/ts/types/common'

export const observeElementSize = (
    target: MaybeRef<HTMLElement> | MaybeRef<HTMLElement>[],
    callback: ResizeObserverCallback
): VoidFunction => {
    const { window } = getClientContext()
    const isSupported = useSupported(() => window && ('ResizeObserver' in window))

    let observer: ResizeObserver | undefined

    const cleanup = (): void => {
        if (observer) {
            observer.disconnect()

            observer = undefined
        }
    }

    const targets = computed(() =>
        isArray(target)
            ? target.map(el => unrefElement(el))
            : [ unrefElement(target) ]
    )

    const stopWatch = watch(
        targets,
        (els) => {
            cleanup()

            if (isSupported.value && window && els.length) {
                observer = new ResizeObserver(callback)

                for (const _el of els) {
                    _el && observer!.observe(_el)
                }
            }
        },
        {
            flush: 'post',
            immediate: true,
            deep: true
        }
    )

    const stop = () => {
        cleanup()
        stopWatch()
    }

    tryOnScopeDispose(stop)

    return stop
}
