<script
    lang="ts"
    setup
>
    import type { AppVirtualListItem, AppVirtualListItemContext } from '~/ts/types/app'

    type Props = {
        items: any[]
        renderAhead?: number
        itemStaticHeight?: number
        itemIndent?: number
        itemHeightManually?: (index: number) => number
        maxContainerHeight?: number
        totalHeightMinus?: number // наприклад, якщо itemIndent не застосовується у останньому елементі при вказаному itemStaticHeight
        indexToScrollAtStart?: number
        disabled?: boolean
        wrapperClass?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        renderAhead: 2,
        itemStaticHeight: undefined,
        itemIndent: 0,
        itemHeightManually: undefined,
        maxContainerHeight: undefined,
        totalHeightMinus: 0,
        indexToScrollAtStart: undefined,
        disabled: false,
        wrapperClass: undefined
    })

    const MIN_RENDER_AHEAD = 2
    const DEFAULT_ITEM_HEIGHT = 10

    const containerRef = ref<HTMLDivElement>()

    const ready = ref<boolean>(false)
    const listHeight = ref<number>(0)

    observeElementSize(containerRef, ([ { contentRect } ]) => {
        listHeight.value = Math.round(contentRect.height)
    })

    const itemContexts = new Map<number, AppVirtualListItemContext>()

    const state = shallowRef<{ start: number, end: number }>({ start: 0, end: 10 })
    const currentList = shallowRef<AppVirtualListItem<any>[]>([])
    const source = shallowRef<any[]>(toRaw(props.items))
    const itemHeights = ref<Record<number, number>>({})
    const wrapperStyle = computed<Record<string, string>>(() => ({
        height: `${ totalHeight.value - offsetTop.value }px`,
        marginTop: `${ offsetTop.value }px`
    }))
    const renderAhead = computed<number>(() => {
        return (props.renderAhead < MIN_RENDER_AHEAD)
            ? MIN_RENDER_AHEAD
            : props.renderAhead
    })

    const getContainerHeight = (): number => {
        if (!props.maxContainerHeight) {
            return containerRef.value.clientHeight
        }

        return containerRef.value.clientHeight > props.maxContainerHeight
            ? props.maxContainerHeight
            : containerRef.value.clientHeight
    }

    const getItemSize = (index: number): number => {
        let height

        if (props.itemHeightManually) {
            height = props.itemHeightManually(index)
        } else {
            height = props.itemStaticHeight ?? itemHeights.value[index]
        }

        return (height || DEFAULT_ITEM_HEIGHT) + props.itemIndent
    }

    const getViewCapacity = (containerSize: number) => {
        const { start = 0 } = state.value
        let sum = 0
        let capacity = 0

        for (let i = start; i < source.value.length; i++) {
            const size = getItemSize(i)
            sum += size
            capacity = i

            if (sum > containerSize) {
                break
            }
        }

        return capacity - start
    }

    const getOffset = (scrollTop: number) => {
        let sum = 0
        let offset = 0

        for (let i = 0; i < source.value.length; i++) {
            const size = getItemSize(i)
            sum += size

            if (sum >= scrollTop) {
                offset = i
                break
            }
        }

        return offset + 1
    }

    const calculateRange = () => {
        if (!containerRef.value || props.disabled) {
            return
        }

        const offset = getOffset(containerRef.value.scrollTop)
        const viewCapacity = getViewCapacity(getContainerHeight())

        const from = offset - renderAhead.value
        const to = offset + viewCapacity + renderAhead.value

        state.value = {
            start: from < 0 ? 0 : from,
            end: to > source.value.length
                ? source.value.length
                : to
        }

        currentList.value = source.value
            .slice(state.value.start, state.value.end)
            .map((item, index) => ({
                item,
                index: index + state.value.start
            }))
    }

    const getDistanceTop = (index: number) => {
        return source.value
            .slice(0, index)
            .reduce<number>((sum, _, i) => sum + getItemSize(i), 0)
    }

    const offsetTop = computed<number>(() => getDistanceTop(state.value.start))

    const totalHeight = computed<number>(() => {
        return source.value.reduce<number>((sum, _, index) => sum + getItemSize(index), 0) - props.totalHeightMinus
    })

    const containerStyle = computed<string | undefined>(() => {
        return '--virtual-list-max-height:' + (
            props.maxContainerHeight ? (props.maxContainerHeight + 'px') : 'none'
        )
    })

    const scrollTo = (index: number) => {
        if (!containerRef.value) {
            return
        }

        containerRef.value.scrollTop = getDistanceTop(index)

        calculateRange()
    }

    const onItemSetContext = (context: AppVirtualListItemContext): void => {
        if (itemContexts.has(context.index)) {
            return
        }

        itemContexts.set(context.index, context)

        if (props.itemHeightManually) {
            itemHeights.value[context.index] = props.itemHeightManually(context.index)
        } else {
            itemHeights.value[context.index] = props.itemStaticHeight ?? context.getHeight() ?? 0
        }
    }

    watch([
        listHeight,
        isReactive(props.items) ? props.items : () => props.items,
        isReactive(props.disabled) ? props.disabled : () => props.disabled
    ], () => {
        if (!ready.value) {
            return
        }

        source.value = toRaw(props.items)

        if (props.disabled) {
            currentList.value = source.value.map((item, index) => ({ item, index }))

            state.value = { start: 0, end: props.items.length }
        }

        scrollTo(0)

        calculateRange()
    })

    onMounted(() => {
        if (props.disabled) {
            currentList.value = source.value.map((item, index) => ({ item, index }))

            state.value = { start: 0, end: props.items.length }
        }

        if (isNumber(props.indexToScrollAtStart)) {
            scrollTo(props.indexToScrollAtStart)
        }

        setTimeout(() => {
            ready.value = true

            calculateRange()
        }, 20)
    })

    defineExpose({ scrollTo })
</script>

<template>
    <template v-if="props.disabled">
        <slot
            v-for="{ index, item } in currentList"
            :key="index"
            :item-attrs="{
                key: index,
                index,
                item
            }"
        />
    </template>

    <div
        v-else
        key="virtual-scroll"
        ref="containerRef"
        :class="[ 'overflow-y-auto h-full max-h-[var(--virtual-list-max-height)]', { 'scrollbar-hide': !ready } ]"
        :style="containerStyle"
        @scroll.passive="calculateRange()"
    >
        <div
            class="relative flex flex-col w-full"
            :class="props.wrapperClass"
            :style="wrapperStyle"
        >
            <slot
                v-for="{ index, item } in currentList"
                :key="index"
                :item-attrs="{
                    key: index,
                    index,
                    item,
                    style: { height: itemHeights[index] && (itemHeights[index] + 'px') }
                }"
                :item-on="{ 'item:set-context': onItemSetContext }"
            />
        </div>
    </div>
</template>
